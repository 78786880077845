import React from "react";
import { ReactElement } from "react";
import { Temporal } from "@js-temporal/polyfill";
import { ReactComponent as VisaIcon } from "images/icons/credit-card-visa.svg";
import { ReactComponent as MastercardIcon } from "images/icons/credit-card-mastercard.svg";
import { ReactComponent as AmexIcon } from "images/icons/credit-card-amex.svg";
import { ReactComponent as GenericCardIcon } from "images/icons/credit-card-1-alternate.svg";
import { ReactComponent as AfterpayIcon } from "images/icons/payment-afterpay.svg";
import { paymentMethodWalletTypeImage, WalletType } from "./wallet_type"

type BrandString = "visa" | "mastercard" | "amex" | string;

const BrandIcon = ({ brand } : { brand: BrandString }) => {
  switch (brand) {
    case "visa":
      return <VisaIcon className="icon streamline-icon is-medium space-m-none" />;

    case "mastercard":
      return <MastercardIcon className="icon streamline-icon is-medium space-m-none" />;

    case "amex":
      return <AmexIcon className="icon streamline-icon is-medium space-m-none" />;

    default:
      return <GenericCardIcon className="icon streamline-icon is-medium space-m-none" />;
  }
}

interface IPaymentMethod {
  type: 'card' | 'afterpay_clearpay';

  shortDescription(): ReactElement;
  hasExpired(): boolean;
  isDefaultPaymentMethod(): boolean;
}

interface IPaymentMethodExpiry {
  year: number;
  month: number;
}

class PaymentMethodCard implements IPaymentMethod {
  type: 'card' = 'card' as const;
  id: string;
  brand: BrandString;
  walletType: WalletType;
  expiry: IPaymentMethodExpiry;
  description: string;
  saveCard: boolean;
  isDefault: boolean;

  constructor({
    id,
    brand,
    walletType,
    expiryYear,
    expiryMonth,
    description,
    saveCard,
    isDefault,
  } : {
    id: string;
    brand: BrandString;
    walletType: WalletType;
    expiryYear: number;
    expiryMonth: number;
    description: string;
    saveCard: boolean;
    isDefault: boolean;
  }) {
    this.id = id;
    this.brand = brand;
    this.walletType = walletType;
    this.expiry = { year: expiryYear, month: expiryMonth };
    this.description = description;
    this.saveCard = saveCard;
    this.isDefault = isDefault;
  }

  shortDescription() : ReactElement {
    return (
      <>
        <div className="is-flexbox align-items-center is-flex-gap-xs">
          {this.walletTypeImage()}<BrandIcon brand={this.brand} /> <b>{this.description}</b>
        </div>
      </>
    );
  }

  walletTypeImage() {
    return paymentMethodWalletTypeImage(this.walletType)
  }

  hasExpired(): boolean {
    const expiryYearMonth = new Temporal.PlainYearMonth(this.expiry.year, this.expiry.month);
    const now = Temporal.Now.zonedDateTimeISO()
    const todayYearMonth = new Temporal.PlainYearMonth(now.year, now.month);

    return Temporal.PlainYearMonth.compare(todayYearMonth, expiryYearMonth) > 0;
  }

  isDefaultPaymentMethod(): boolean {
    return this.isDefault;
  }
}

class PaymentMethodAfterpay implements IPaymentMethod {
  type: 'afterpay_clearpay' = 'afterpay_clearpay' as const;
  id: string;
  name: string;
  email: string;
  isDefault: boolean;

  constructor(id: string, name: string, email: string, isDefault: boolean) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.isDefault = isDefault;
  }

  shortDescription(): ReactElement {
    return (
        <div className="is-flexbox align-items-center">
          <AfterpayIcon className="icon streamline-icon is-medium" /> <b>Afterpay</b>
        </div>
    );
  }

  hasExpired(): boolean {
    return false;
  }

  isDefaultPaymentMethod(): boolean {
    return false;
  }
}

type PaymentMethod = PaymentMethodCard | PaymentMethodAfterpay;

interface IConfirmForPaymentResponse {
  success: true | false;
}

class SuccessConfirmForPaymentResponse implements IConfirmForPaymentResponse {
  success: true = true as const;
  next: string;
  paymentIntentStatus?: PaymentIntentStatus;
  paymentIntentSecret?: string;

  constructor(
    next: string,
    paymentIntentStatus?: PaymentIntentStatus,
    paymentIntentSecret?: string,
  ) {
    this.next = next;
    this.paymentIntentSecret = paymentIntentSecret;
    this.paymentIntentStatus = paymentIntentStatus;
  }
}

class FailureConfirmForPaymentResponse implements IConfirmForPaymentResponse {
  success: false = false as const;
  error: string;
  next?: string;
  verifyPhoneNumber?: string;

  constructor(error: string, next?: string, verifyPhoneNumber?: string) {
    this.error = error;
    this.next = next;
    this.verifyPhoneNumber = verifyPhoneNumber;
  }
}

type ConfirmForPaymentResponse = SuccessConfirmForPaymentResponse | FailureConfirmForPaymentResponse;
type PaymentIntentStatus = "requires_action" | "succeeded" | "not_required";

export {
  WalletType,
  PaymentMethod,
  PaymentMethodCard,
  PaymentMethodAfterpay,
  PaymentIntentStatus,
  ConfirmForPaymentResponse,
  SuccessConfirmForPaymentResponse,
  FailureConfirmForPaymentResponse,
  BrandIcon,
};
