import React from "react";
import { PaymentMethod, PaymentMethodAfterpay, PaymentMethodCard } from "./model";
import { ReactComponent as MoveExpandVerticalIcon } from "images/icons/move-expand-vertical.svg";
import { ReactComponent as BinIcon } from "images/icons/bin-2.svg";
import { WalletType, paymentMethodWalletTypeName } from "./wallet_type";

const PaymentMethodPreview = ({
  paymentMethod,
  savedPaymentMethods,
  isSavedCard,
  onChangeSavedPaymentMethod,
  removeUnsavedPaymentMethod,
}: {
  paymentMethod: PaymentMethod;
  savedPaymentMethods: PaymentMethod[];
  isSavedCard: boolean;
  onChangeSavedPaymentMethod: () => void;
  removeUnsavedPaymentMethod: () => void;
}) => {
  const preview = () => {
    switch (paymentMethod.type) {
      case "card":
        return (
          <PaymentMethodCardPreview
            paymentMethod={paymentMethod}
            hasOtherSavedPaymentMethods={savedPaymentMethods.length > 1}
            isSavedCard={isSavedCard}
            onChangeSavedPaymentMethod={onChangeSavedPaymentMethod}
            removeUnsavedPaymentMethod={removeUnsavedPaymentMethod}
          />
        );

      case "afterpay_clearpay":
        return (
          <PaymentMethodAfterpayPreview
            paymentMethod={paymentMethod}
            hasOtherSavedPaymentMethods={savedPaymentMethods.length > 0}
            removeUnsavedPaymentMethod={removeUnsavedPaymentMethod}
          />
        );

      default:
        throw "Don't know how to preview payment method type";
    }
  };

  return (
    <>
      <p className="is-tiny-muted-text">Paying with...</p>

      {preview()}
    </>
  );
}

const PaymentMethodCardPreview = ({
  paymentMethod,
  hasOtherSavedPaymentMethods,
  isSavedCard,
  onChangeSavedPaymentMethod,
  removeUnsavedPaymentMethod,
} : {
  paymentMethod: PaymentMethodCard;
  hasOtherSavedPaymentMethods: boolean;
  isSavedCard: boolean;
  onChangeSavedPaymentMethod: () => void;
  removeUnsavedPaymentMethod: () => void;
}) => {
  const expiryElement = () => {
    const hasExpired = paymentMethod.hasExpired();
    const textStyle = hasExpired ? "is-error-text" : "is-muted-text"

    return <p className={textStyle}>
      {walletTypeName()} {hasExpired ? "Expired" : "Expires"} {paymentMethod.expiry.month.toString().padStart(2, "0")}/{paymentMethod.expiry.year.toString().slice(-2)}
    </p>
  }

  const walletTypeName = () => {
    if (paymentMethod.walletType != WalletType.None) {
      return paymentMethodWalletTypeName(paymentMethod.walletType) + ","
    }
  }

  if (hasOtherSavedPaymentMethods && isSavedCard === true) {
    return (
      <button
        onClick={onChangeSavedPaymentMethod}
        className={`callout has-border flex-grow-1 is-flexbox align-items-center justify-content-between space-mb-sm ${paymentMethod.hasExpired() ? 'has-error' : '' }`}
        role="button"
        data-cy="change-saved-pm"
      >
        <div>
          {paymentMethod.shortDescription()}

          {expiryElement()}
        </div>

        <MoveExpandVerticalIcon className="icon streamline-icon space-m-none" />
      </button>
    );
  } else {
    return (
      <div
        className="callout has-border flex-grow-1 is-flexbox align-items-center justify-content-between space-mb-sm"
        role="button"
      >
        <div>
          {paymentMethod.shortDescription()}

          {expiryElement()}
        </div>

        {!isSavedCard ? <button className="button is-icon-only" onClick={removeUnsavedPaymentMethod}><BinIcon className="icon streamline-icon space-m-none" /></button> : <></>}
      </div>
    );
  }
}

const PaymentMethodAfterpayPreview = ({
  paymentMethod,
  hasOtherSavedPaymentMethods,
  removeUnsavedPaymentMethod,
} : {
    paymentMethod: PaymentMethodAfterpay;
    hasOtherSavedPaymentMethods: boolean;
    removeUnsavedPaymentMethod: () => void;
}) => {
  return (
    <div className="callout has-border flex-grow-1 is-flexbox align-items-center justify-content-between space-mb-sm">
      <div>
        {paymentMethod.shortDescription()}

        <p className="is-muted-text">{paymentMethod.email}</p>
      </div>

      {hasOtherSavedPaymentMethods ? <button className="button is-icon-only" onClick={removeUnsavedPaymentMethod}><BinIcon className="icon streamline-icon space-m-none" /></button> : <></>}
    </div>
  );
}

export { PaymentMethodPreview }
