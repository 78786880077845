import { ActionEvent, Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "content", "indicator", "item", "prevButton", "nextButton"
  ];

  declare contentTarget: HTMLElement;
  declare indicatorTargets: HTMLElement[];
  declare itemTargets: HTMLElement[];

  declare prevButtonTarget: HTMLButtonElement;
  declare hasPrevButtonTarget: boolean;

  declare nextButtonTarget: HTMLButtonElement;
  declare hasNextButtonTarget: boolean;

  connect() {
    const opts: IntersectionObserverInit = {
      threshold: .75,
    };

    const intersectionObserver = new IntersectionObserver(this.onIntersection.bind(this), opts);

    this.itemTargets.forEach((target) => intersectionObserver.observe(target));
  }

  onIndicatorClick(e: ActionEvent) {
    const { index } = e.params;

    if (index == "prev") {
      this.contentTarget.scrollBy({ left: -this.contentTarget.offsetWidth, behavior: "smooth" });
    } else if (index == "next") {
      this.contentTarget.scrollBy({ left: this.contentTarget.offsetWidth, behavior: "smooth" });
    } else {
      this.contentTarget.scrollTo({ left: index * this.contentTarget.offsetWidth, behavior: "smooth" });
    }
  }

  private setIndicatorActive(index: number) {
    if (!this.hasPrevButtonTarget || !this.hasNextButtonTarget || !this.indicatorTargets[index]) return;

    this.indicatorTargets.forEach((target, targetIndex) => {
      if (targetIndex != index) target.classList.remove("is-active")
    });

    this.indicatorTargets[index].classList.add("is-active");

    this.prevButtonTarget.disabled = false;
    this.nextButtonTarget.disabled = false;

    if (index == 0) {
      this.prevButtonTarget.disabled = true;
    } else if (index == this.itemTargets.length - 1) {
      this.nextButtonTarget.disabled = true;
    }
  }

  private onIntersection(entries: IntersectionObserverEntry[]) {
    const index = entries[0].target.getAttribute("data-index")

    if (index) {
      this.setIndicatorActive(parseInt(index));
    }
  }
}
