import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripePaymentElementChangeEvent, StripePaymentElementOptions } from "@stripe/stripe-js";
import React, { useMemo } from "react";
import { useCallback, useState } from "react";
import { classNames } from "src/common_util";
import { ReactComponent as ScooterIcon } from "images/animated/animated_scooter.svg";
import { ReactComponent as AlertCircleIcon } from "images/icons/alert-circle.svg";
import { createPaymentMethod } from "./create_payment_method";
import { PaymentMethod } from "./model";

const NewPaymentMethodForm = ({
  onSuccess,
  onCancel,
  suppressHeader,
  setPaymentDetailsComplete,
  billingName,
  billingEmail,
  allowSaveCard,
  onSaveCardChange,
  confirmButtonCopy,
}: {
  onSuccess?: (newPaymentMethod: PaymentMethod) => Promise<string | undefined>;
  onCancel?: () => void;
  suppressHeader?: boolean;
  setPaymentDetailsComplete?: (isComplete: boolean) => void;
  billingName: string;
  billingEmail: string;
  allowSaveCard: boolean;
  onSaveCardChange?: (newSaveCard: boolean) => void;
  confirmButtonCopy?: string;
}) => {
  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements)
    throw "Somehow Stripe isn't ready!";

  const [saveCard, setSaveCard] = useState<boolean>(true);
  const [currentElementsType, setCurrentElementsType] = useState<string>("card");
  const [formComplete, setFormComplete] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string | undefined>(undefined);

  const onElementsChange = useCallback((event: StripePaymentElementChangeEvent) => {
    setCurrentElementsType(event.value.type);
    setFormComplete(event.complete);
    setPaymentDetailsComplete && setPaymentDetailsComplete(event.complete);
  }, [setPaymentDetailsComplete]);

  const onContinue = useCallback(async () => {
    setIsLoading(true);

    try {
      const newPaymentMethod = await createPaymentMethod({
        stripe: stripe,
        elements: elements,
        billingName: billingName,
        billingEmail: billingEmail,
        saveCard: saveCard,
      });

      if (onSuccess) {
        const error = await onSuccess(newPaymentMethod);

        if (error) {
          setSubmitError(error);
        } else {
          elements.getElement("payment")?.clear();
        }
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsLoading(false);
    }
  }, [stripe, elements, onSuccess, billingName, billingEmail, saveCard]);

  const cancelCallback = useCallback(() => {
    if (onCancel) {
      setSubmitError("");

      onCancel();
    }

  }, [onCancel])

  const paymentElementOptions : StripePaymentElementOptions = {
    layout: "accordion",
  };

  const successButtonClasses = classNames({
    "button": true,
    "is-primary": true,
    "is-full-width": true,
    "is-loading": isLoading,
  });

  const icon = () => {
    if (isLoading) {
      return (
        <span className="icon custom-icon">
          <ScooterIcon className="animated-scooter" />
        </span>
      );
    }
  };

  const text = () => {
    if (isLoading)
      return "Saving..";

    return confirmButtonCopy ? confirmButtonCopy : "Continue";
  }

  const header = () => {
    if (suppressHeader)
      return null;

    return (
      <h5 className="space-mb-md">Select a payment method</h5>
    );
  }

  const continueButton = () => {
    if (!onSuccess)
      return;

    return (
      <button
        type="button"
        disabled={!formComplete || isLoading}
        className={successButtonClasses}
        data-cy="save-new-pm"
        onClick={onContinue}
      >
        {icon()}
        <span>{text()}</span>
      </button>
    );
  }

  const cancelButton = () => {
    if (!onCancel)
      return;

    return (
      <button
        type="button"
        className="button is-full-width space-mt-sm"
        onClick={cancelCallback}
      >
        Cancel
      </button>
    );
  }

  const buttonSection = () => {
    if (!onCancel && !onSuccess)
      return;

    return (
      <section className="has-gradient is-stuck-bottom">
        {continueButton()}
        {cancelButton()}
      </section>
    );
  }

  const submitErrorElement = useMemo(() => {
    if (!submitError) return null;

    return (
      <section className="section">
        <div className="callout has-border">
          <AlertCircleIcon className="icon streamline-icon" />
          <div>{submitError}</div>
        </div>
      </section>
    )
  }, [submitError])

  const toggleSaveCard = useCallback((event: React.FormEvent<HTMLInputElement>) => {
    setSaveCard(event.currentTarget.checked);
    onSaveCardChange && onSaveCardChange(event.currentTarget.checked);
  }, [onSaveCardChange]);

  const saveCardCheckbox = () => {
    if (!allowSaveCard)
      return;

    if (currentElementsType != "card")
      return;

    return (
      <section data-testid="save-card-section">
        <div className="checkbox">
          <input type="checkbox" name="save_card" id="save_card" value="" onChange={toggleSaveCard} checked={saveCard} />
          <label htmlFor="save_card">Save this card for future use</label>
        </div>
      </section>
    );
  }

  return (
    <section className="container width-constraint-narrow space-ml-none">
      {header()}

      <PaymentElement
        options={paymentElementOptions}
        onChange={onElementsChange}
      />

      {submitErrorElement}

      {saveCardCheckbox()}

      {buttonSection()}
    </section>
  );
}

export { NewPaymentMethodForm };
