import React, { useCallback, useMemo, useState } from "react";
import { Popover } from "src/components/popover";
import { PaymentMethod } from "./model";
import { PaymentMethodPreview } from "./payment_method_preview";
import { settingsSavedCardsPath } from "src/routes";
import { ReactComponent as AddIcon } from "images/icons/add.svg";
import { ReactComponent as AlertCircleIcon } from "images/icons/alert-circle.svg";
import { ReactComponent as VisaMark } from "images/payment-marks/visa.svg"
import { ReactComponent as MastercardMark } from "images/payment-marks/mastercard.svg"
import { ReactComponent as AfterpayMark } from "images/payment-marks/afterpay.svg"
import { ReactComponent as GooglePay } from "images/payment-marks/google-pay.svg";
import { ReactComponent as ApplePay } from "images/payment-marks/apple-pay.svg";
import { NewPaymentMethodForm } from "./new_payment_method_form";

const SavedPaymentMethodSelector = ({
  selectedPaymentMethod,
  savedPaymentMethods,
  setSelectedPaymentMethod,
  billingName,
  billingEmail,
  allowSaveCard,
  enabledPaymentMethods,
  confirmButtonCopy,
}: {
  selectedPaymentMethod: PaymentMethod;
  savedPaymentMethods: PaymentMethod[];
  setSelectedPaymentMethod: (newPaymentMethod: PaymentMethod) => Promise<string | undefined>;
  billingName: string;
  billingEmail: string;
  allowSaveCard: boolean;
  enabledPaymentMethods: string[];
  confirmButtonCopy?: string;
}) => {
  const hasOtherSavedPaymentMethods = savedPaymentMethods.length > 1;
  const isSavedCard = savedPaymentMethods.map((pm) => pm.id).includes(selectedPaymentMethod.id);
  const [popoverSelected, setPopoverSelected] = useState<PaymentMethod>(selectedPaymentMethod);

  const OtherSavedPaymentMethodUnexpanded = (showPopover: () => void) => {
    const defaultSavedPaymentMethod = savedPaymentMethods[savedPaymentMethods.length - 1];

    const removeUnsavedPaymentMethod = useCallback(async () => {
      await setSelectedPaymentMethod(defaultSavedPaymentMethod);
    }, [defaultSavedPaymentMethod]);

    const onClick = () => {
      if (hasOtherSavedPaymentMethods && isSavedCard)
        showPopover();
    }

    return (
      <>
        <PaymentMethodPreview
          paymentMethod={selectedPaymentMethod}
          savedPaymentMethods={savedPaymentMethods}
          isSavedCard={isSavedCard}
          onChangeSavedPaymentMethod={onClick}
          removeUnsavedPaymentMethod={removeUnsavedPaymentMethod}
        />
      </>
    );
  };

  const OtherSavedPaymentMethodExpanded = (hidePopover: () => void) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [submitError, setSubmitError] = useState<string | undefined>(undefined)

    const selectedOptionChanged = (event: React.FormEvent<HTMLInputElement>) => {
      const newSaved = savedPaymentMethods.find(pm => pm.id == event.currentTarget.value);

      if (newSaved) {
        setPopoverSelected(newSaved);
      }
    };

    const submitForm = async () => {
      setIsSubmitting(true)
      const submitError = await setSelectedPaymentMethod(popoverSelected);

      if (submitError) {
        setSubmitError(submitError)
      } else {
        // Close the popover if there are no errors to display
        hidePopover();
      }

      setIsSubmitting(false)
    };

    const cancel = useCallback(() => {
      hidePopover();

      // Reset error display on cancel
      setSubmitError(undefined)
    }, [hidePopover])

    const cardOptions = savedPaymentMethods.map(pm => {
      const monthAndYearFormatter = (month: number, year: number): string => {
        return `${month.toString().padStart(2, "0")}/${year.toString().slice(-2)}`
      }

      const cardTypeSwitch = () => {
        switch (pm.type) {
          case 'card':
            return (
              <label className="justify-content-between" htmlFor={pm.id}>
                {pm.shortDescription()}
                <span className={`badge ${pm.hasExpired() ? " is-important" : "space-pr-xs is-muted-text"}`}>
                  {`${pm.hasExpired() ? "Expired" : "Expires"} ${monthAndYearFormatter(pm.expiry.month, pm.expiry.year)}`}
                </span>
              </label>
            )
          case 'afterpay_clearpay':
            return (
              <label className="justify-content-between" htmlFor={pm.id}>{pm.shortDescription()}</label>
          )
        }
      }

      return (
        <div className="radio is-button" data-cy='saved-card' key={pm.id}>
          <input
            type="radio"
            id={pm.id}
            name="selected-saved-payment-method"
            value={pm.id}
            onChange={selectedOptionChanged}
            checked={pm.id == popoverSelected.id}
          />

          {cardTypeSwitch()}
        </div>
      );
    });

    const shouldPreventConfirm = isSubmitting || popoverSelected.hasExpired()

    const submitErrorElement = useMemo(() => {
      if (!submitError) return null;

      return (
        <div className="callout has-border">
          <AlertCircleIcon className="icon streamline-icon" />
          <div>{submitError}</div>
        </div>
      )
    }, [submitError])

    return (
      <>
        <section className="container">
          <h5>Select a card</h5>
        </section>

        <section className="container">
          {cardOptions}

          <a href={settingsSavedCardsPath()} className="button space-mb-md is-full-width" onClick={hidePopover}>
            Manage cards
          </a>

          {submitErrorElement}
        </section>

        <section className="has-gradient is-stuck-bottom">
          <button className="button is-primary" disabled={shouldPreventConfirm} onClick={submitForm}
            data-cy="continue-change-saved-pm">
            {confirmButtonCopy || "Continue"}
          </button>

          <button className="button space-mt-sm" disabled={isSubmitting} onClick={cancel}>
            Cancel
          </button>
        </section>
      </>
    );
  }

  const NewPaymentMethodUnexpanded = (showPopover: () => void) => {
    return (
      <button className="button" data-cy="other-payment-method" onClick={showPopover}>
        <AddIcon className="icon streamline-icon" />
        <span>Pay another way</span>
      </button>
    );
  }

  const NewPaymentMethodExpanded = (hidePopover: () => void) => {
    const onNewPaymentMethodAdded = useCallback(async (newPaymentMethod: PaymentMethod) => {
      const submitError = await setSelectedPaymentMethod(newPaymentMethod);

      if (submitError) {
        return submitError;
      } else {
        setPopoverSelected(newPaymentMethod);
        hidePopover();
      }
    }, [hidePopover]);

    return (
      <NewPaymentMethodForm
        onSuccess={onNewPaymentMethodAdded}
        onCancel={hidePopover}
        billingName={billingName}
        billingEmail={billingEmail}
        allowSaveCard={allowSaveCard}
        confirmButtonCopy={confirmButtonCopy}
      />
    );
  }

  const afterpayMark = () => {
    if (!enabledPaymentMethods.includes("afterpay_clearpay"))
      return;

    return (
      <AfterpayMark className="payment-mark responsive-image" data-testid="afterpay-mark" />
    );
  }

  return (
    <section className="container width-constraint-narrow space-ml-none">
      <Popover
        unexpandedContent={OtherSavedPaymentMethodUnexpanded}
        expandedContent={OtherSavedPaymentMethodExpanded}
        options={{ isModal: true, isFloatingOnDesktop: true }}
        onTransition={() => setPopoverSelected(selectedPaymentMethod)}
      />

      <Popover
        unexpandedContent={NewPaymentMethodUnexpanded}
        expandedContent={NewPaymentMethodExpanded}
        options={{ isModal: true, isFloatingOnDesktop: true, isFullHeightOnMobile: true }}
      />

      <div className="is-flexbox is-flex-gap-md space-pt-lg space-pb-sm">
        <VisaMark className="payment-mark responsive-image" />
        <MastercardMark className="payment-mark responsive-image" />
        <ApplePay className="payment-mark responsive-image" />
        <GooglePay className="payment-mark responsive-image" />
        {afterpayMark()}
      </div>
    </section>
  );
}

export { SavedPaymentMethodSelector };
