import { Controller } from "@hotwired/stimulus";
import { Order, OrderItem } from "src/order";
import { OrderItemApiClient } from "src/order_item_api_client";
import bin2 from "images/icons/bin-2.svg";

export default class extends Controller {
  static targets = ["modal", "authenticityToken", "addItemUrl", "removeItemUrl", "content",
    "initialState", "subtotal", "deliveryFee", "subscriptionDiscount", "serviceFee", "total", "discount",
    "checkoutButton","checkoutButtonText", "checkoutButtonAnimation", "mobileViewCartButton",
    "subscriptionDiscountLineItem", "discountLineItem", "serviceFeeLineItem", "itemModal"
  ];

  declare modalTarget: HTMLElement;
  declare itemModalTarget: HTMLElement;
  declare authenticityTokenTarget: HTMLInputElement;
  declare addItemUrlTarget: HTMLInputElement;
  declare removeItemUrlTarget: HTMLInputElement;
  declare contentTarget: HTMLElement;
  declare hasContentTarget: boolean;
  declare hasInitialStateTarget: boolean;
  declare initialStateTarget: HTMLElement;
  declare subtotalTarget: HTMLElement;
  declare deliveryFeeTarget: HTMLElement;
  declare subscriptionDiscountTarget: HTMLElement;
  declare subscriptionDiscountLineItemTarget: HTMLElement;
  declare serviceFeeTarget: HTMLElement;
  declare serviceFeeLineItemTarget: HTMLElement;
  declare discountTarget: HTMLElement;
  declare discountLineItemTarget: HTMLElement;
  declare totalTarget: HTMLElement;
  declare checkoutButtonTarget: HTMLButtonElement;
  declare checkoutButtonAnimationTarget: HTMLSpanElement;
  declare checkoutButtonTextTarget: HTMLSpanElement;

  declare mobileViewCartButtonTarget: HTMLElement;

  client : OrderItemApiClient | null = null;
  order! : Order;

  connect() {
    if ( this.hasInitialStateTarget === false){
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('showCart') === "true") {
      this.show();
    }

    window.setTimeout(() => { // Timeout and wait for mobile-cart.controller to load
      if (this.initialStateTarget.innerText == ""){
        this.displayEmptyCart();
        this.mobileViewCartButtonTarget.classList.add("is-completely-hidden")
      } else {
        this.order = Order.fromObject(JSON.parse(this.initialStateTarget.innerText));
        this.populateCart(this.order);
      }
      const authenticityToken = this.authenticityTokenTarget.value;
      const addItemUrl = this.addItemUrlTarget.value;
      const removeItemUrl = this.removeItemUrlTarget.value;

      this.client = new OrderItemApiClient(authenticityToken, addItemUrl, removeItemUrl);
    }, 10);
  }

  show() {
    this.modalTarget.classList.add("is-active");
  }

  hide() {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete("showCart");

    window.history.replaceState({}, "", `${window.location.origin}${window.location.pathname}${urlParams}`);

    this.modalTarget.classList.remove("is-active");
  }

  async addItem(ev: CustomEvent) {
    const response = this.client!.addItem(ev.detail)
    response.then(
      (response) => {
        this.order = Order.fromObject(response);

        this.updateMenuItemQuantityBadges();
        this.populateCart(this.order);

        if (window.dataLayer && response.metricsEvent) {
          window.dataLayer.push(response.metricsEvent);
        }
        const event = new Event("closeModal", { bubbles: true });
        this.element.dispatchEvent(event);
      },
      (reason) => {
        const event = new CustomEvent("addItemFailed", { bubbles: true, detail: reason['message'] });
        this.itemModalTarget.dispatchEvent(event);
      }
    )
  }

  updateMenuItemQuantityBadges() {
    [].forEach.call(document.querySelectorAll("[data-menu-item-id]"), (target: HTMLElement) => {
      const menuItemId = target.getAttribute("data-menu-item-id");

      if (this.order.orderItems.find(orderItem => orderItem.menuItemId.toString() == menuItemId)) {
        const totalOfMenuItem = this.order.orderItems.reduce((acc, orderItem) => {
          if (orderItem.menuItemId.toString() == menuItemId) {
            return acc + orderItem.quantity;
          } else {
            return acc;
          }
        }, 0)

        target.innerText = totalOfMenuItem.toString();
        target.classList.remove("is-visually-hidden");
        target.setAttribute("aria-hidden", "false");
      } else {
        target.classList.add("is-visually-hidden");
        target.setAttribute("aria-hidden", "true");
      }
    });
  }

  populateCart(order: Order) {
    if (order.orderItems.length === 0) {
      this.displayEmptyCart();
      this.mobileViewCartButtonTarget.classList.add("is-completely-hidden")
    } else {
      this.mobileViewCartButtonTarget.classList.remove("is-completely-hidden")
      this.contentTarget.innerHTML = "";
      this.checkoutButtonTarget.removeAttribute("disabled");

      order.orderItems.forEach(orderItem => {
        const orderItemElement = this.buildOrderItem(orderItem);
        this.contentTarget.appendChild(orderItemElement);
      });
    }

    this.populateCartTotals(order);
  }

  displayEmptyCart() {
    if (this.hasContentTarget === true) {
      this.contentTarget.innerText = "There's nothing in your cart";
      this.checkoutButtonTarget.setAttribute("disabled", "true");
    }
  }

  buildOrderItem(orderItem : OrderItem) : HTMLElement {
    const container = document.createElement("div");
    container.classList.add("line-item");
    container.classList.add("has-full-description");
    container.setAttribute("data-order-item-id", orderItem.id);
    container.setAttribute("data-cy", "order-item");

    const titleAndQuantityContainer = document.createElement("div");
    titleAndQuantityContainer.classList.add("is-flexbox");
    titleAndQuantityContainer.classList.add("flex-grow-1");
    titleAndQuantityContainer.classList.add("align-items-start");
    container.appendChild(titleAndQuantityContainer);

    const title = document.createElement("div");
    title.classList.add("title");
    title.innerText = orderItem.name;
    titleAndQuantityContainer.appendChild(title);

    const quantity = document.createElement("div");
    quantity.classList.add("badge");
    quantity.classList.add("is-quantity");
    quantity.innerText = orderItem.quantity.toString();
    titleAndQuantityContainer.appendChild(quantity);

    if (orderItem.description) {
      const description = document.createElement("div");
      description.classList.add("description");
      const descriptionInner = document.createElement("p");
      descriptionInner.classList.add("is-tiny-text");
      descriptionInner.classList.add("is-muted-text");
      descriptionInner.innerText = orderItem.description;
      description.appendChild(descriptionInner);
      container.appendChild(description);
    }

    if (orderItem.orderOptionSets.length !== 0) {
      const optionSets = document.createElement("div");
      optionSets.classList.add("item-options");
      for (const optionSet of orderItem.orderOptionSets) {
        const optionEl = document.createElement("p");
        optionEl.classList.add("is-tiny-text");
        const optionsString = optionSet.options.map(o => o.name).join(", ");
        optionEl.innerText = `${optionSet.name}: ${optionsString}`;
        optionSets.appendChild(optionEl);
      }
      container.appendChild(optionSets);
    }

    const subtotal = document.createElement("div");
    subtotal.classList.add("price");
    subtotal.innerText = orderItem.totalPrice;
    container.appendChild(subtotal);

    // const editOrderItem = document.createElement("a");
    // editOrderItem.classList.add("chip");
    // editOrderItem.classList.add("is-action");

    // const editOrderItemText = document.createElement("span");
    // editOrderItemText.innerText = "Edit";

    // const editOrderItemIcon = this.buildIcon("pencil");

    // editOrderItem.appendChild(editOrderItemIcon);
    // editOrderItem.appendChild(editOrderItemText);
    // container.appendChild(editOrderItem);

    const removeOrderItem = document.createElement("a");
    removeOrderItem.classList.add("chip");
    removeOrderItem.classList.add("is-action");

    const deleteOrderItemText = document.createElement("span");
    deleteOrderItemText.innerText = "Delete";

    const deleteOrderItemIcon = this.buildImage(bin2);

    removeOrderItem.appendChild(deleteOrderItemIcon);
    removeOrderItem.appendChild(deleteOrderItemText);
    container.appendChild(removeOrderItem);

    removeOrderItem.addEventListener("click", this.removeItemFromCart.bind(this, orderItem));

    return container;
  }

  buildImage(src: string) : HTMLImageElement {
    const imgEl = document.createElement("img");
    imgEl.src = src;
    imgEl.classList.add("icon", "streamline-icon");
    return imgEl;
  }

  async removeItemFromCart(orderItem: OrderItem) {
    const response = await this.client!.removeItem(orderItem);
    this.order = Order.fromObject(response);

    this.updateMenuItemQuantityBadges();
    this.populateCart(this.order);

    if (window.dataLayer && response.metricsEvent) {
      window.dataLayer.push(response.metricsEvent);
    }
  }

  populateCartTotals(order: Order) {
    this.subtotalTarget.innerText = order.subtotal;
    this.deliveryFeeTarget.innerText = order.deliveryFee;

    if (order.serviceFee != null) {
      this.serviceFeeLineItemTarget.classList.remove("is-completely-hidden");
      this.serviceFeeTarget.innerText = order.serviceFee;
    } else {
      this.serviceFeeLineItemTarget.classList.add("is-completely-hidden");
      this.serviceFeeTarget.innerText = "";
    }

    this.totalTarget.innerText = order.total;

    if (order.subscriptionDeliveryFeeSavings != null) {
      this.subscriptionDiscountLineItemTarget.classList.remove("is-completely-hidden");
      this.subscriptionDiscountTarget.innerText = order.subscriptionDeliveryFeeSavings;
    } else {
      this.subscriptionDiscountLineItemTarget.classList.add("is-completely-hidden");
      this.subscriptionDiscountTarget.innerText = "";
    }

    if (order.discount) {
      this.discountLineItemTarget.classList.remove("is-completely-hidden");
      this.discountTarget.innerText = order.discount;
    } else {
      this.discountLineItemTarget.classList.add("is-completely-hidden");
      this.discountTarget.innerText = "";
    }

    const event = new CustomEvent("populateCartButtonTotal", { bubbles: true , detail: order.subtotal });
    this.element.dispatchEvent(event);
  }

  openDeliveryDetails(event: Event) {
    event.stopPropagation();
    const ev = new Event("openDeliveryDetails", { bubbles: true });
    this.element.dispatchEvent(ev);
  }

  checkout() {
    this.checkoutButtonTarget.classList.toggle("is-loading");
    this.checkoutButtonTarget.disabled = true
    this.checkoutButtonTextTarget.innerText = this.checkoutButtonTextTarget.innerText == "Please wait..." ? "Apply" : "Please wait...";
    this.checkoutButtonAnimationTarget.classList.toggle("is-completely-hidden");
    window.location.href = this.checkoutButtonTarget.dataset.redirect!;
  }
}
