import React, { useCallback, useEffect, useState } from "react";
import { Popover } from "src/components/popover";
import { Temporal } from "@js-temporal/polyfill";
import { parseInvoices } from "src/checkout/decoders";
import { Invoice } from "src/storefront/types/invoice";
import { ReactComponent as Remove } from "images/icons/remove.svg";

const SubscriptionInvoice = () => {
  const [invoices, setInvoices] = useState<Invoice[]>([])

  const formatPrice = (price: number) : string => {
    return (price / 100).toLocaleString("en-NZ", { style: "currency", currency: "NZD" });
  }

  const fetchInvoices = useCallback(async () : Promise<Invoice[]> => {
    const response = await fetch(`/mates_rates/retrieve_invoices`, {
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      }
    })

    const data = await response.json();

    if (!response.ok) {
      return [];
    }

    setInvoices(parseInvoices(data))
    return parseInvoices(data)
  }, [])

  useEffect(() => {
    fetchInvoices()
  }, [fetchInvoices]);

  const PaymentHistoryExpanded = (hidePopover: () => void) => {
    const handleTimeMutation = (time: string) => {
      return Temporal.Instant.from(time).toZonedDateTimeISO("Pacific/Auckland").toLocaleString("en-NZ", { dateStyle: "long" })
    }

    return (
      <>
        <div className="container space-pb-none">
          <h5>Payment history</h5>
        </div>

        {invoices.map(inv => {
          return (
            <React.Fragment key={inv.id}>
              <div className="container" >
                <div className="is-flexbox justify-content-between space-mb-sm">
                  <h5 className="is-bold-text">{handleTimeMutation(inv.created)}</h5>
                  <h5 className="is-bold-text">{formatPrice(inv.amountPaid)}</h5>
                </div>
                <div>
                  <p className="is-tiny-muted-text">Mates Rates subscription (Monthly), for the period {handleTimeMutation(inv.currentPeriodStart)} – {handleTimeMutation(inv.currentPeriodEnd)}.</p>
                </div>
                {/* Use .pdf extension so customer app is able to intercept with it's own pdf handling */}
                <a className="button is-full-width" href={`/mates_rates/${inv.invoiceStripeId}/download_invoice.pdf`}>Download invoice</a>
              </div>
              <hr className="space-m-none" />
            </React.Fragment>
          )}
        )}

        <section className="has-gradient is-hidden-above-tablet is-stuck-bottom">
          <div className="container is-flexbox justify-content-center">
            <button className="button is-icon-only has-large-icon is-primary" aria-label="Close" onClick={hidePopover}>
              <Remove className="icon streamline-icon" />
            </button>
          </div>
        </section>
      </>
    );
  }

  const PaymentHistoryUnexpanded = (showPopover: () => void) => {
    return (
      <div className="container">
        <button className="button" onClick={() => {
          showPopover()
        }}>
          <span>Payment history</span>
        </button>
      </div>
    );
  }

  return <>
    <Popover
      unexpandedContent={PaymentHistoryUnexpanded}
      expandedContent={PaymentHistoryExpanded}
      options={{ isModal: true, isFloatingOnDesktop: true, isFullHeightOnMobile: true }}
    />
  </>
}

export { SubscriptionInvoice }